import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';

export const NextProject = ({ locale, projectList, currentElement }) => {
  const nextIndex =
    projectList.findIndex((el) => {
      return el.slug === currentElement.slug;
    }) + 1;
  const nextProject =
    projectList[nextIndex === projectList.length ? 0 : nextIndex];
  return (
    <Link to={`${locale === 'it' ? '' : `/${locale}`}/${nextProject.slug}`}>
      <div
        className={'footer-next-project'}
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 59%, rgba(0, 0, 0, 0.95) 100%), url(${nextProject?.stageImage?.file.url}`,
        }}
        // onClick={() => {
        //   console.log('projectList', nextProject);
        //   navigate(`${locale === 'it' ? '' : locale}/${nextProject.slug}`);
        // }}
      >
        <p>Next Work</p>
      </div>
    </Link>
  );
};

export default ({ locale, currentElement }) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulGallery {
          nodes {
            projectsList {
              title
              slug
              stageImage {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const g = data.allContentfulGallery.nodes[0].projectsList;
      return (
        <NextProject
          locale={locale}
          projectList={g}
          currentElement={currentElement}
        />
      );
    }}
  />
);
