import React, { useEffect, useState, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import Vimeo from '@u-wave/react-vimeo';
import ReactMarkdown from 'react-markdown';
import { Icon } from '../components/menu/menu';
import { SharingIcons } from '../components/SharingIcons';
import NextProject from '../components/NextProject';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from 'simple-react-lightbox';
import './lightbox.css';
import { Helmet } from 'react-helmet';

export const makeMobileImage = (image: any, mobileImage: any) => {
  return typeof window !== 'undefined' && window.innerWidth < 500 && mobileImage
    ? mobileImage
    : image;
};

export const availableBlocks = {
  ContentfulVideoBlock: ({ block }: any) => (
    <div className={'project-block'}>
      <Vimeo video={block?.videoID} responsive />
    </div>
  ),
  ContentfulImageBlock: ({ block, vertical, onClickImage }: any) => (
    <div className={'project-block'}>
      <img
        // onClick={onClickImage}
        alt={block?.title}
        src={makeMobileImage(
          block?.image?.file?.url,
          block?.imageMobile?.file?.url
        )}
      />
    </div>
  ),
  ContentfulImageTextBlock: ({ block, onClickImage }: any) => (
    <div className={'project-block'}>
      <div className={'image-text-block'}>
        <div className={'block-double'}>
          <img
            // onClick={onClickImage}
            alt={block?.title}
            src={makeMobileImage(
              block?.image?.file?.url,
              block?.imageMobile?.file?.url
            )}
          />
        </div>

        <div className={'block-double'}>
          <h2 className={'image-text-block-slogan'}>{block.slogan?.slogan}</h2>
          <ReactMarkdown
            className={'image-text-block-text'}
            source={block.text?.text}
            escapeHtml={false}
          />
        </div>
      </div>
    </div>
  ),
};
const ProjectDetail = ({
  pageContext: { slug, locale, projectList },
  data: {
    allContentfulProject: { nodes },
  },
  ...props
}: any) => {
  const {
    title,
    stageImage,
    stageImageMobile,
    slogan,
    projectLabel,
    description,
    blocks,
    client,
    galleryImage,
  } = nodes[0];
  useEffect(() => {
    const body = document.querySelector('body');
    body?.setAttribute('style', 'overflow-y: scroll;overflow-x: hidden;');
  }, []);

  const stageImageUrl =
    typeof window !== 'undefined' &&
    window.innerWidth < 800 &&
    stageImageMobile?.file.url
      ? stageImageMobile?.file.url
      : stageImage?.file.url;

  return (
    <>
      <Helmet>
        <title>{`Edge Fx - ${title}`}</title>
        <meta name="title" content={`Edge Fx - ${title}`} />
        <meta name="description" content={slogan.slogan.replace(/\n/g, ' ')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://edgefx.tv/${slug}`} />
        <meta property="og:title" content={`Edge Fx - ${title}`} />
        <meta
          property="og:description"
          content={slogan.slogan.replace(/\n/g, ' ')}
        />
        <meta property="og:image" content={galleryImage.file.url} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="edgefx.tv" />
        <meta property="twitter:url" content={`https://edgefx.tv/${slug}`} />
        <meta name="twitter:title" content="Edge Fx" />
        <meta
          name="twitter:description"
          content={slogan.slogan.replace(/\n/g, ' ')}
        />
        <meta name="twitter:image" content={galleryImage.file.url} />
      </Helmet>

      <SimpleReactLightbox>
        <div className={'intro-container intro-container-scroll'}>
          <Link to={`${locale === 'it' ? '' : `/${locale}`}/gallery/`}>
            <div className={'chevron-control'}>
              <svg
                enableBackground="new 0 0 515.556 515.556"
                height="24"
                viewBox="0 0 515.556 515.556"
                width="24"
              >
                <path
                  stroke="#fff"
                  fill="#fff"
                  d="m128.885 257.778 257.778-257.778v128.886l-128.889 128.892 128.897 128.886-.008 128.892z"
                />
              </svg>
            </div>
          </Link>
          <div className={'detail-page'}>
            <div
              className={'stage'}
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.3) 59%, rgba(0, 0, 0, 0.55) 100%), url(${stageImageUrl}`,
              }}
            >
              <div className={'slogan'}>
                {slogan?.slogan && (
                  <ReactMarkdown source={slogan?.slogan} escapeHtml={false} />
                )}
              </div>
            </div>
            <div className={'description-container'}>
              <div className={'description-title'}>
                <h1>{title}</h1>
                {client && <p>{client}</p>}
              </div>
              <div className={'description'}>
                {description?.description && (
                  <ReactMarkdown
                    source={description?.description}
                    escapeHtml={false}
                  />
                )}
              </div>
            </div>
            <SRLWrapper>
              <div className={'blocks-container'}>
                {blocks.map((block: any, index: number) => (
                  <React.Fragment key={`block-${index}`}>
                    {block?.slogan && (
                      <div className={'block-slogan'}>
                        <ReactMarkdown
                          source={block?.slogan?.slogan}
                          escapeHtml={false}
                        />
                      </div>
                    )}
                    <div className={'detail-block'}>
                      {block?.blocks?.length === 3
                        ? (() => {
                            const Renderer1 =
                              availableBlocks[block?.blocks[0].__typename];
                            const Renderer2 =
                              availableBlocks[block?.blocks[1].__typename];
                            const Renderer3 =
                              availableBlocks[block?.blocks[2].__typename];

                            return (
                              <>
                                <div className={`block-double`}>
                                  <Renderer1
                                    block={block?.blocks[0]}
                                    vertical
                                  />
                                </div>
                                <div className={`block-tri`}>
                                  <Renderer2
                                    block={block?.blocks[1]}
                                    vertical
                                  />

                                  <Renderer3
                                    block={block?.blocks[2]}
                                    vertical
                                  />
                                </div>
                              </>
                            );
                          })()
                        : block?.blocks?.map((el: any, idx: number) => {
                            const Renderer = availableBlocks[el.__typename];

                            return (
                              <React.Fragment
                                key={`block-renderer-${index}-${idx}`}
                              >
                                {block?.blocks?.length > 1 ? (
                                  <div className={`block-double`}>
                                    <Renderer
                                      key={`${idx}-${index}`}
                                      block={el}
                                    />
                                  </div>
                                ) : (
                                  <Renderer
                                    key={`${idx}-${index}`}
                                    block={el}
                                  />
                                )}
                              </React.Fragment>
                            );
                          })}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </SRLWrapper>

            <div className={'footer'}>
              <h2 className={'footer-title'}>
                {locale === 'it'
                  ? 'Adesso che succede?!'
                  : 'This is how we do it!'}
              </h2>

              <p className={'footer-paragraph'}>
                {locale === 'it'
                  ? `Te lo spieghiamo in 55 caratteri:
Noi creiamo nuovi mondi e incredibili interconnessioni. `
                  : `We create new worlds and incredible interconnections.`}
              </p>

              <div className={'footer-button'}>
                <a href={'mailto:info@edgefx.tv'}>
                  <button className="btn">
                    <svg
                      width="350px"
                      height="70px"
                      viewBox="0 0 350 70"
                      className="border"
                    >
                      <polyline
                        points="349,1 349,59 1,59 1,1 349,1"
                        className="hl-line"
                      />
                    </svg>
                    <span>
                      {locale === 'it'
                        ? 'CONNETTIAMOCI ORA!'
                        : "LET'S CONNECT NOW!"}
                    </span>
                  </button>
                </a>
              </div>
              <NextProject currentElement={nodes[0]} locale={locale} />

              <div className={'logo-footer'}>
                <Link to={`${locale === 'it' ? '' : `/${locale}/`}`}>
                  <img src={'/LOGO.svg'} alt={'logo'} />
                </Link>
              </div>

              <div className={'footer-contact-block'}>
                <h3>General Inquires</h3>
                <a href={'mailto:info@edgefx.tv'}>
                  <p>info@edgefx.tv</p>
                </a>
                <h3>Telephone</h3>
                <a href={'tel:+393403153593'}>
                  <p>+39 3403153593</p>
                </a>
                <h3>Social</h3>
                <SharingIcons />

                <p className={'footer-copyright'}>
                  All rights reserved © 2021 P.Iva: 11990330018
                </p>
              </div>
            </div>
          </div>
        </div>
      </SimpleReactLightbox>
    </>
  );
};

export const ImageBlockFragment = graphql`
  fragment ImageBlockFragment on ContentfulImageBlock {
    __typename
    title
    image {
      file {
        url
      }
    }
    imageMobile {
      file {
        url
      }
    }
  }
`;

export const ImageTextBlockFragment = graphql`
  fragment ImageTextBlockFragment on ContentfulImageTextBlock {
    __typename
    slogan {
      slogan
    }
    text {
      text
    }
    image {
      file {
        url
      }
    }
    imageMobile {
      file {
        url
      }
    }
  }
`;

export const VideoBlockFragment = graphql`
  fragment VideoBlockFragment on ContentfulVideoBlock {
    __typename
    videoTitle
    videoID
  }
`;

export const ProjectBlockFragment = graphql`
  fragment ProjectBlockFragment on ContentfulDetailBlock {
    __typename
    slogan {
      slogan
    }
    blocks {
      ...VideoBlockFragment
      ...ImageBlockFragment
      ...ImageTextBlockFragment
    }
  }
`;

export const query = graphql`
  query($slug: String!, $locale: String!) {
    allContentfulProject(
      filter: { node_locale: { eq: $locale }, slug: { eq: $slug } }
    ) {
      nodes {
        title
        slug
        description {
          description
        }
        stageImage {
          file {
            url
          }
        }
        stageImageMobile {
          file {
            url
          }
        }
        slogan {
          slogan
        }
        projectLabel
        blocks {
          ...ProjectBlockFragment
        }
        category {
          categoryName
        }
        galleryImage {
          file {
            url
          }
        }
        client
      }
    }
  }
`;

export default ProjectDetail;
